import React, { useEffect, useState } from 'react';
import { Alert, Container, Row } from 'react-bootstrap';
import './CoursesIndex.css';
import Loader from '../Loader/Loader';
import axios from '../Config/axios';
import CourseItem from './CourseItem';
import { getUserToken } from '../Session/Session';

const CoursesIndex = () => {
    const [categories, setCategories] = useState([]);
    const [courses, setCourses] = useState([]);
    const [isCategories, setIsCategories] = useState(true);
    const [activeCategory, setActiveCategory] = useState(0);
    const [admittedIds, setAdmittedIds] = useState([]);
    const [admittedStatusIds, setAdmittedStatusIds] = useState([]);
    const token = getUserToken();
    const getCategories = async (category) => {
        try {
            const res = await axios.get(`/web/paid-courses/categories?category=${category}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setAdmittedIds(res?.data?.data?.admitted_ids);
            setAdmittedStatusIds(res?.data?.data?.user_admitted_status);
            setCourses(res?.data?.data?.courses);
            setCategories(res?.data?.data?.categories);
            setIsCategories(false);
        } catch (error) { }
    }

    useEffect(() => {
        document.title = "Courses | Nahid24.com"
        getCategories('');
    }, []);

    const handleCategoryClick = (categoryId, index) => {
        setActiveCategory(index);
        getCategories(categoryId);
    };

    if (isCategories) {
        return (
            <div className='books-section'>
                <Loader />
            </div>

        );
    }

    return (
        <Container>
            <h2 className="text-center my-2 page-title">পেইড কোর্স</h2>
            <div className='courses-category-container'>
                {
                    <>
                        <div
                            className={`courses-category-item ${activeCategory === 0 ? 'course-category-active' : ''}`}
                            key="0"
                            onClick={() => handleCategoryClick('', 0)}
                        >
                            All
                        </div>
                        {
                            categories.map((category, index) => (
                                <div
                                    className={`courses-category-item ${activeCategory === category.id ? 'course-category-active' : ''}`}
                                    key={index + 1}
                                    onClick={() => handleCategoryClick(category.id, category.id)}
                                >
                                    {category.title}
                                </div>
                            ))
                        }
                    </>
                }
            </div>
            <Row className='py-3'>
                {
                    courses.length > 0 ?
                        courses.map((course, index) => {
                            return <CourseItem admittedStatusIds={admittedStatusIds} admittedIds={admittedIds} course={course} key={`category_${index}`} />
                        })
                        :
                        <div className='col-md-12 text-center'>
                            <Alert variant='warning'>Data not found!</Alert>
                        </div>
                }
            </Row>
        </Container>
    );
};

export default CoursesIndex;