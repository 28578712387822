import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from './components/Pages/AboutUs/AboutUs';
import ContactUs from './components/Pages/ContactUs/ContactUs';
import Layout from './components/Layout/Layout';
import NotFound from './components/Pages/NotFound/NotFound';
import BookDetails from './components/BookDetails/BookDetails';
import Login from './components/Auth/Login/Login';
import Register from './components/Auth/Register/Register';
import Forget from './components/Auth/Forget/Forget';
import AllOrder from './components/AllOrder/AllOrder';
import Cart from './components/Cart/Cart';
import Checkout from './components/Checkout/Checkout';
import Profile from './components/Profile/Profile';
import PrivacyPolicy from './components/Pages/PrivacyPolicy/PrivacyPolicy';
import OrderReceipt from './components/AllOrder/OrderReceipt/OrderReceipt';
import PaidCategories from './components/PaidExams/PaidCategories/PaidCategories';
import PaidExamNames from './components/PaidExams/PaidExamNames/PaidExamNames';
import PaidExamNameDetails from './components/PaidExams/PaidExamNames/PaidExamNameDetails/PaidExamNameDetails';
import PaidExamCheckOut from './components/PaidExams/PaidExamCheckOut/PaidExamCheckOut';
import MyPaidExam from './components/Dashboard/MyPaidExam/MyPaidExam';
import MyPaidExamTitle from './components/Dashboard/MyPaidExam/MyPaidExamTitle/MyPaidExamTitle';
import MyPaidExamTopics from './components/Dashboard/MyPaidExam/MyPaidExamTopics/MyPaidExamTopics';
import PaidExamModule from './components/Dashboard/MyPaidExam/PaidExamModule/PaidExamModule';
import PaidExamModuleResult from './components/Dashboard/MyPaidExam/PaidExamModuleResult/PaidExamModuleResult';
import MyPaidExamResults from './components/Dashboard/MyPaidExam/MyPaidExamResults/MyPaidExamResults';
import MyPaidExamScore from './components/Dashboard/MyPaidExam/MyPaidExamScore/MyPaidExamScore';
import MyPaidExamLeaderBoard from './components/Dashboard/MyPaidExam/MyPaidExamLeaderBoard/MyPaidExamLeaderBoard';
import PrivateRoute from './components/Auth/PrivateRoute/PrivateRoute';
import PaidExamOrderReceipt from './components/AllOrder/PaidExamOrderReceipt/PaidExamOrderReceipt';
import Settings from './components/Profile/Settings/Settings';
import MyPaidExamLeaderBoardAll from './components/Dashboard/MyPaidExam/MyPaidExamLeaderBoardAll/MyPaidExamLeaderBoardAll';
import LibraryList from './components/LibraryList/LibraryList';
import RequestToRemoveData from './components/Profile/request/RequestToRemoveData';
import Guest from './components/Checkout/Guest';
import CourseVideoPlayer from './components/PaidCourses/CourseVideoPlayer';
import CoursesIndex from './components/PaidCourses';
import CourseDetails from './components/PaidCourses/courseDetails';
import CourseCheckout from './components/PaidCourses/checkout/PaidCourseCheckout';
import MyCourses from './components/PaidCourses/MyCourses/MyCourses';
import MyCourseItem from './components/PaidCourses/MyCourses/MyCourseItem/MyCourseItem';
import MyCourse from './components/PaidCourses/MyCourses/MyCourse/MyCourse';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="home" element={<Home/>} /> */}
          <Route path="book/:slug" element={<BookDetails />} />
          <Route path="library" element={<LibraryList />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="policy" element={<PrivacyPolicy />} />
          <Route path="guest" element={<Guest />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forget" element={<Forget />} />
          <Route path="paid-exam-category" element={<PaidCategories />} />
          <Route path="course-video" element={<CourseVideoPlayer />} />
          <Route element={<PrivateRoute />}>
            <Route path="courses" element={<CoursesIndex />} />
            <Route path="course-detail/:id" element={<CourseDetails />} />
            <Route path="my-order" element={<AllOrder />} />
            <Route path="course-checkout" element={<CourseCheckout />} />
            <Route path="paid-exam/checkout" element={<PaidExamCheckOut />} />
            <Route path="paid-exam-name/:id" element={<PaidExamNames />} />
            <Route path="paid-exam-name-details/:id" element={<PaidExamNameDetails />} />
            <Route path="my-courses" element={<MyCourses />} />
            <Route path="my-course/:id" element={<MyCourse />} />
            <Route path="my-paid-exams" element={<MyPaidExam />} />
            <Route path="my-paid-exam/:id" element={<MyPaidExamTitle />} />
            <Route path="my-paid-exam-topics/:id" element={<MyPaidExamTopics />} />
            <Route path="paid-exam/:id" element={<PaidExamModule />} />
            <Route path="paid-exam-score/:id" element={<MyPaidExamScore />} />
            <Route path="paid-exam-result/:id" element={<PaidExamModuleResult />} />
            <Route path="my-paid-exam-results" element={<MyPaidExamResults />} />
            <Route path="my-paid-exam-leader-board/:id" element={<MyPaidExamLeaderBoard />} />
            <Route path="my-paid-exam-leader-board-all/:id" element={<MyPaidExamLeaderBoardAll />} />
            <Route path="order-receipt/:id" element={<OrderReceipt />} />
            <Route path="paid-exam-order-receipt/:id" element={<PaidExamOrderReceipt />} />
            <Route path="profile" element={<Profile />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="delete-account" element={<Settings />} />
            <Route path="request-to-remove-data" element={<RequestToRemoveData />} />
          </Route>
          <Route path="cart" element={<Cart />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


export default App;
