import React, { useEffect, useState } from 'react';
import './MyCourse.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Container, Row } from 'react-bootstrap';
import parse from 'html-react-parser';
import Loader from '../../../Loader/Loader';
import BreadcrumbComponent from '../../../BreadcrumbComponent/BreadcrumbComponent';
import axios from '../../../Config/axios';
import YouTubePlayer from '../../../YouTubeEmbed/YouTubeEmbed';
const MyCourse = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [course, setCourse] = useState([]);
    const [videos, setVideos] = useState([]);
    const [content, setContent] = useState('');
    const [activeContent, setActiveContent] = useState(3);
    const [videoId, setVideoId] = useState('');
    const [loading, setLoading] = useState(true);
    const [noteCount, setNoteCount] = useState(0);
    const homeUrl = `${window.location.protocol}//${window.location.host}`;

    // Detect DevTools and Console Tampering
    // useEffect(() => {
    //     const detectConsole = () => {
    //         const threshold = 160; // Threshold for dev tools detection
    //         if (window.outerHeight - window.innerHeight > threshold || window.outerWidth - window.innerWidth > threshold) {
    //             window.location.href = homeUrl;
    //         }
    //     };
    //     const consoleInterval = setInterval(detectConsole, 1000);

    //     // Cleanup intervals on unmount
    //     return () => {
    //         clearInterval(consoleInterval);
    //     };
    // }, [homeUrl]);


    // useEffect(() => {
    //     // Disable right-click
    //     document.addEventListener('contextmenu', (e) => e.preventDefault());

    //     // Disable copy, cut, and paste
    //     document.addEventListener('copy', (e) => e.preventDefault());
    //     document.addEventListener('cut', (e) => e.preventDefault());
    //     document.addEventListener('paste', (e) => e.preventDefault());

    //     // Disable inspect element and other shortcuts
    //     document.addEventListener('keydown', (e) => {
    //         if (
    //             e.key === 'F12' ||
    //             (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J')) ||
    //             (e.ctrlKey && e.key === 'U')
    //         ) {
    //             e.preventDefault();
    //         }
    //     });

    //     // Cleanup listeners on unmount

    //     return () => {
    //         document.removeEventListener('contextmenu', (e) => e.preventDefault());
    //         document.removeEventListener('copy', (e) => e.preventDefault());
    //         document.removeEventListener('cut', (e) => e.preventDefault());
    //         document.removeEventListener('paste', (e) => e.preventDefault());
    //         document.removeEventListener('keydown', (e) => e.preventDefault());
    //     };
    // }, []);

    const [breadcrumbs, setBreadcrumbs] = useState([
        { name: 'Home', "url": '/', active: false },
        { name: 'Profile', "url": '/profile', active: false },
        { name: 'My Paid Course', "url": '/my-courses', active: false },
    ]);

    useEffect(() => {
        document.title = "My Paid Course Details | Nahid24.com"
        if (!id) { navigate('/'); }
    }, [id, navigate]);

    const getCourse = async () => {
        try {
            const res = await axios.get(`/web/paid-course-details/${id}`);
            setLoading(false);

            if (res.data.data.error === false) {
            } else {
                // setContent('<div class="row"><div class="col-md-12 text-center"><div class="text-warning">Comming Soon..</div></div></div>')
                setCourse(res?.data?.data?.course);
                setVideos(res?.data?.data?.videos);
                setBreadcrumbs([
                    ...breadcrumbs,
                    { name: res?.data?.data?.course?.name, "url": `/course-detail/${res?.data?.data?.course?.id}`, active: true },
                ]);
            }
        } catch (error) {
        }
    }
    const handelVideoClick = (id) => {
        setVideoId(id);
    }
    const handelVideoClose = () => {
        setVideoId('');
    }
    // Updated Code: Use useEffect to handle video-related content
    useEffect(() => {
        if (videos.length > 0) {

            // Calculate the total note count
            const count = videos.reduce((total, video) => {
                const noteValue = video.file ? 1 : 0;
                return total + noteValue;
            }, 0);

            setNoteCount(count);

            let html = '<div class="col-md-4"><div class="course-item-list-box">';
            html += videos
                .map((video) => {
                    let lockIcon = '<i class="far fa-play-circle"></i>';
                    let downloadLink = video.url ? video.url : '#';
                    console.log('aftavb')
                    return `
                                <div class="lecture-item video-link" data-url="${downloadLink}">
                                    <span>${video.serial ?? '*'}</span>
                                    <div class="d-flex justify-content-between">
                                        <span>${video.title}</span>
                                        <span>${lockIcon}</span>
                                    </div>
                                </div>
                        `;
                })
                .join('');
            html += '</div></div>';
            setContent(html); // Only sets content after rendering is complete
        }
    }, [videos]); // Depend on `videos` to avoid re-render issues

    useEffect(() => {
        getCourse();
    }, [id]);

    // Add a click event listener to the dynamically rendered content
    useEffect(() => {
        const container = document.querySelector('.course-content');
        const handleDynamicClick = (event) => {
            const target = event.target.closest('.video-link');
            if (target) {
                document.querySelectorAll('.video-link.video_active').forEach((el) => {
                    el.classList.remove('video_active');
                });
                target.classList.add('video_active');
                const url = target.getAttribute('data-url');
                handelVideoClick(url);
            }
        };
        if (container) {
            container.addEventListener('click', handleDynamicClick);
        }
        return () => {
            if (container) {
                container.removeEventListener('click', handleDynamicClick);
            }
        };
    }, [content]);
    const handelContent = (item) => {
        setActiveContent(item)
        setVideoId('');
        if (item === 1) {
            let html = '<div class="row"><div class="col-md-12 text-center"><div class="text-warning">Comming Soon..</div></div></div>';
            setContent(html)
        } else if (item === 4) {
            let routineHtml = '';
            if (course.file) {
                routineHtml = `<div class="row"><div class="d-flex justify-content-center py-4 col-md-12"><a class="routine-button" href="${course.file}" download>Download Rounite</a></div></div>`
            }
            setContent(routineHtml);
        } else if (item === 2) {
            let html = '<div class="row"><div class="col-md-8">';
            if (videos.length > 0) {
                html += videos.map((video, index) => {
                    let lockIcon = '<i class="fas fa-lock-open"></i>';
                    let downloadLink = video.file ? video.file : '#';
                    return `
                        <a href="${downloadLink}" download target="_blank" class="lecture-download-link">
                            <div class="lecture-item">
                                <span><span class="me-2">${video.serial ?? '*'}</span><span><i class="far fa-arrow-alt-circle-down"></i></span></span>
                                <div class="d-flex justify-content-between">
                                    <span>${video.title ?? '[নাই]'} (PDF)</span>
                                    <span>${lockIcon}</span>
                                </div>
                            </div>
                        </a>
                    `;
                }).join('');
            }
            html += '</div></div>';
            setContent(html)
        } else if (item === 3) {
            if (videos.length > 0) {
                let html = '<div class="col-md-4"><div class="course-item-list-box">';
                html += videos
                    .map((video) => {
                        let lockIcon = '<i class="far fa-play-circle"></i>';
                        let downloadLink = video.url ? video.url : '#';
                        return `
                                    <div class="lecture-item video-link" data-url="${downloadLink}">
                                        <span>${video.serial ?? '*'}</span>
                                        <div class="d-flex justify-content-between">
                                            <span>${video.title}</span>
                                            <span>${lockIcon}</span>
                                        </div>
                                    </div>
                            `;
                    })
                    .join('');
                html += '</div></div>';
                setContent(html); // Only sets content after rendering is complete
            }
        } else if (item === 5) {
            let routineHtml = '';
            if (course.extra_url) {
                routineHtml = `<div class="row"><div class="d-flex justify-content-center py-4 col-md-12"><a class="routine-button" href="${course.extra_url}" download>Join Our Group</a></div></div>`
            }
            setContent(routineHtml);
        } else if (item === 6) {
            navigate('/my-paid-exams')
            setContent('');
        }

    };

    if (loading) {
        return (
            <div className='books-section'>
                <Loader />
            </div>

        );
    }

    return (
        <div>
            <Container>
                <div className='breadcrumb-container'>
                    <BreadcrumbComponent breadcrumbs={breadcrumbs} />
                    {
                        course?.name ? <h1 className='course-title'>{course?.name}</h1> : ''
                    }
                </div>
                {
                    course?.name ?
                        <div className='py-md-5 py-4'>
                            <Row>
                                <div className='order1 pt-md-0 pt-md-0 pt-0 col-md-12'>
                                    <div className="course-left-container-content">
                                        <div className={`course-left-container-content-item ${activeContent === 3 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(3)}>Videos</div>
                                        {
                                            noteCount > 0 && <div className={`course-left-container-content-item ${activeContent === 2 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(2)}>Notes</div>
                                        }

                                        <div className={`course-left-container-content-item ${activeContent === 4 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(4)}>Routine</div>

                                        {
                                            course.exam_quantity > 0 && <div className={`course-left-container-content-item ${activeContent === 6 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(6)}>Exam</div>
                                        }
                                        {
                                            course.extra_url && <div className={`course-left-container-content-item ${activeContent === 5 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(5)}>FB Group</div>
                                        }


                                        <div className={`course-left-container-content-item ${activeContent === 1 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(1)}>FAQ</div>


                                    </div>
                                    <div className=' pt-md-5 pt-4 course-content'>

                                        {
                                            activeContent === 3 ?
                                                <div className="row">
                                                    {content ? parse(content) : ''}
                                                    <div className='col-md-8'>
                                                        {
                                                            videoId &&
                                                            (
                                                                <div className='videoContainer'>
                                                                    {/* <span onClick={handelVideoClose} className='videoClose'>X</span> */}
                                                                    <button
                                                                        type="button"
                                                                        className="btn videoClose"
                                                                        onClick={handelVideoClose}
                                                                    >
                                                                        ✖
                                                                    </button>
                                                                    <div className='videoBox'><YouTubePlayer videoId={videoId} /></div>

                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {content ? parse(content) : ''}
                                                </>
                                        }
                                    </div>
                                </div>
                            </Row>
                        </div>
                        : <div className="py-md-5 py-4">
                            <Row>
                                <div className='text-center pt-md-0 pt-md-0 pt-0 col-md-12'>
                                    <Alert variant='warning'>Data not found!</Alert>
                                </div>
                            </Row>
                        </div>
                }
            </Container >
        </div >
    );
};

export default MyCourse;