import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PaidExamName.css';
const PaidExamName = ({ name, admittedIds, admittedStatusIds }) => {
    const { id, image, title, price } = name;
    return (
        <Col xs={6} md={3} className="mb-4">
            {
                admittedIds.includes(id) ?
                    admittedStatusIds.includes(id) ?
                        <Link to={`/my-paid-exam/${id}`} className="text-decoration-none category-link">
                            <div className="name-box">
                                <img src={image} alt={title} />
                                <h3>{title}</h3>
                                {
                                    admittedIds.includes(id) ?
                                        <span className="name-price">Admitted</span>
                                        :
                                        <span className="name-price">৳ {price}</span>
                                }
                            </div>
                        </Link>
                        :
                        <div className="name-box">
                            <img src={image} alt={title} />
                            <h3>{title}</h3>
                            {
                                <span className="name-price">Processing</span>
                            }
                        </div>
                    :
                    <Link to={`/paid-exam-name-details/${id}`} className="text-decoration-none category-link">
                        <div className="name-box">
                            <img src={image} alt={title} />
                            <h3>{title}</h3>
                            {
                                admittedIds.includes(id) ?
                                    <span className="name-price">Admitted</span>
                                    :
                                    <span className="name-price">৳ {price}</span>
                            }
                        </div>
                    </Link>
            }
        </Col>
    );
};

export default PaidExamName;