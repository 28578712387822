import React, { useEffect, useState } from 'react';
import './courseDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../Config/axios'
import Loader from '../../Loader/Loader';
import { Container, Modal, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../BreadcrumbComponent/BreadcrumbComponent';
import parse from 'html-react-parser';
import YouTubePlayer from '../../YouTubeEmbed/YouTubeEmbed';
import { createPaidExamCartObject } from '../../Functions/Functions';
import { setPaidCourseCartItems } from '../../Session/Session';

const CourseDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [course, setCourse] = useState([]);
    const [videos, setVideos] = useState([]);
    const [isCourse, setIsCourse] = useState(true);
    const [content, setContent] = useState('');
    const [activeContent, setActiveContent] = useState(1);
    const [show, setShow] = useState(false);
    const [isClick, setClick] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const homeUrl = `${window.location.protocol}//${window.location.host}`;

    // // Detect DevTools and Console Tampering
    //     useEffect(() => {
    //         const detectConsole = () => {
    //             const threshold = 160; // Threshold for dev tools detection
    //             if (window.outerHeight - window.innerHeight > threshold || window.outerWidth - window.innerWidth > threshold) {
    //                 window.location.href = homeUrl;
    //             }
    //         };
    //         const consoleInterval = setInterval(detectConsole, 1000);
    
    //         // Cleanup intervals on unmount
    //         return () => {
    //             clearInterval(consoleInterval);
    //         };
    //     }, [homeUrl]);

    // useEffect(() => {
    //     // Disable right-click
    //     document.addEventListener('contextmenu', (e) => e.preventDefault());

    //     // Disable copy, cut, and paste
    //     document.addEventListener('copy', (e) => e.preventDefault());
    //     document.addEventListener('cut', (e) => e.preventDefault());
    //     document.addEventListener('paste', (e) => e.preventDefault());

    //     // Disable inspect element and other shortcuts
    //     document.addEventListener('keydown', (e) => {
    //       if (
    //         e.key === 'F12' || 
    //         (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J')) || 
    //         (e.ctrlKey && e.key === 'U')
    //       ) {
    //         e.preventDefault();
    //       }
    //     });

    //     // Cleanup listeners on unmount
    //     return () => {
    //       document.removeEventListener('contextmenu', (e) => e.preventDefault());
    //       document.removeEventListener('copy', (e) => e.preventDefault());
    //       document.removeEventListener('cut', (e) => e.preventDefault());
    //       document.removeEventListener('paste', (e) => e.preventDefault());
    //       document.removeEventListener('keydown', (e) => e.preventDefault());
    //     };
    // }, []);

    const [breadcrumbs, setBreadcrumbs] = useState([
        { name: 'Home', "url": '/', active: false },
        { name: 'Course', "url": '/courses', active: false },
    ]);

    useEffect(() => {
        document.title = "Course Details | Nahid24.com"
        if (!id) { navigate('/'); }
    }, []);

    const getCourse = async () => {
        try {
            const res = await axios.get(`/web/paid-course-details/${id}`);
            if (res.data.data.error === false) {
            } else {
                setContent(res?.data?.data?.course?.description)
                setCourse(res?.data?.data?.course);
                setVideos(res?.data?.data?.videos);
                setIsCourse(false);

                setBreadcrumbs([
                    ...breadcrumbs,
                    { name: res?.data?.data?.course?.name, "url": `/course-detail/${res?.data?.data?.course?.id}`, active: true },
                ]);
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        getCourse();
    }, [id]);



    const handelContent = (item) => {
        setActiveContent(item)
        console.log('course', course);
        if (item === 1) {
            setContent(course.description);
        } else if (item === 2) {
            let routineHtml = '';
            if (course.file) {
                routineHtml = `<div class="row"><div class="d-flex justify-content-center py-4 col-md-12"><a class="routine-button" href="${course.file}" download>Download Rounite</a></div></div>`
            }
            setContent(routineHtml);
        } else if (item === 3) {
            let html = '';
            if (videos.length > 0) {
                html = videos.map((video, index) => {
                    let lockIcon = '<i class="fas fa-lock"></i>';
                    // <i class="fas fa-lock-open"></i>
                    let downloadLink = '#';
                    console.log('video',video.serial)
                    return `
                        <a href="${downloadLink}" class="lecture-download-link">
                            <div class="lecture-item">
                                <span><span class="me-2">${video.serial ?? ''}</span><span><i class="far fa-arrow-alt-circle-down"></i></span></span>
                                <div class="d-flex justify-content-between">
                                    <span>${video.title ?? '[নাই]'}</span>
                                    <span>${lockIcon}</span>
                                </div>
                            </div>
                        </a>
                    `;
                }).join('');
            }
            setContent(html)
        } else if (item === 4) {
            let html = '<div class="row"><div class="col-md-12 text-center"><div class="text-warning">Comming Soon..</div></div></div>';
            setContent(html)
        } else if (item === 5) {
            let html = '<div class="row"><div class="col-md-12 text-center"><div class="text-warning">Comming Soon..</div></div></div>';
            setContent(html)
        }

    };

    const handelCheckout = (item) => {
        setClick(true);
        setPaidCourseCartItems(createPaidExamCartObject(course));
        navigate('/course-checkout');
    }
    if (isCourse || !course) {
        return (
            <div className='books-section'>
                <Loader />
            </div>

        );
    }

    return (
        <div>
            <Container>
                <div className='breadcrumb-container'>
                    <BreadcrumbComponent breadcrumbs={breadcrumbs} />
                    <h1 className='course-title'>{course?.name}</h1>
                </div>
                <div className='py-md-5 py-4'>
                    <Row>
                        <div className='order1 pt-md-0 pt-md-0 pt-0 col-md-8'>
                            <div className="course-left-container-content">
                                <div className={`course-left-container-content-item ${activeContent === 1 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(1)}>Details</div>
                                <div className={`course-left-container-content-item ${activeContent === 2 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(2)}>Routine</div>
                                <div className={`course-left-container-content-item ${activeContent === 3 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(3)}>Notes</div>
                                <div className={`course-left-container-content-item ${activeContent === 4 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(4)}>Review</div>
                                <div className={`course-left-container-content-item ${activeContent === 5 ? 'couse-left-item-active' : ''}`} onClick={() => handelContent(5)}>FAQ</div>
                            </div>
                            <div className=' pt-md-5 pt-4 course-content'>
                                {content ? parse(content) : ''}
                            </div>
                        </div>
                        <div className='order2  col-md-4'>
                            <div className='course-detail-sidebar'>
                                <div className='course-sidebar-video'>
                                    <img src={course.image} className="w-100" alt="course" />
                                    <div className='course-video-overlay'>
                                        <div className='course-sidebar-icon' onClick={handleShow}>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"></path></svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-5 d-flex justify-content-center align-items-center flex-column pb-4">
                                    <span className="d-block text-center pb-4 course-sidebar-price">
                                        <span className="course-sidebar-base-price">
                                            {course.offer_price > 0 ? <s>{course.price}৳</s> : ''}
                                        </span>{course.offer_price > 0 ? `${course.offer_price}৳` : `${course.price}৳`}
                                    </span>
                                    {
                                        isClick ? <button className="course-sidebar-buy-button"><Loader /></button> : <button className="course-sidebar-buy-button" onClick={() => handelCheckout(course)}>Buy Now</button>
                                    }
                                </div>
                                <div className="d-flex justify-content-between align-items-center pt-3">
                                    <span className="course-sidebar-title">Duration</span>
                                    <span className="course-sidebar-value">{course.duration}</span>
                                </div>
                                {
                                    course.students > 0 ?
                                        <div className="d-flex justify-content-between align-items-center pt-3">
                                            <span className="course-sidebar-title">Student</span>
                                            <span className="course-sidebar-value">{course.students}</span>
                                        </div>
                                        : ''
                                }

                                <div className="d-flex justify-content-between align-items-center pt-3">
                                    <span className="course-sidebar-title">Videos</span>
                                    <span className="course-sidebar-value">{course.video_quantity}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center pt-3">
                                    <span className="course-sidebar-title">Exam</span>
                                    <span className="course-sidebar-value">{course.exam_quantity}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center pt-3">
                                    <span className="course-sidebar-title">PDF</span>
                                    <span className="course-sidebar-value">{course.pdf_quantity}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center pt-3">
                                    <span className="course-sidebar-title">Language</span>
                                    <span className="course-sidebar-value">বাংলা</span>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </Container>
            <Modal className='course-detail-modal' size="lg" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className='p-0 course-modal-header'>
                <button
                    type="button"
                    className="btn btn-custom-close"
                    onClick={handleClose}
                >
                    ✖
                </button>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <YouTubePlayer videoId={course.url} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CourseDetails;